<template>
    <div>
        <component :is="component" />
    </div>
</template>
<script>
    import StaticPageMixin from '@core/mixins/StaticPageMixin';
    import TermsOfUse_en  from './TermsOfUse_en.vue';
    import TermsOfUse_fr  from './TermsOfUse_fr.vue';
    import TermsOfUse_de  from './TermsOfUse_de.vue';
    import TermsOfUse_es  from './TermsOfUse_es.vue';
    import TermsOfUse_it  from './TermsOfUse_it.vue';
    import TermsOfUse_nl  from './TermsOfUse_nl.vue';

    export default {
        name: 'TermsOfUse',
        components: {
            /* eslint-disable vue/no-unused-components */
            TermsOfUse_en,
            TermsOfUse_fr,
            TermsOfUse_de,
            TermsOfUse_es,
            TermsOfUse_it,
            TermsOfUse_nl
            /* eslint-enable vue/no-unused-components */
        },
        mixins: [ StaticPageMixin ],
        computed: {
            component() {
                return `TermsOfUse_${this.language}`;
            }
        }
    };
</script>
